/* eslint-disable */

<template>
  <div id="Lgx315088047483912192">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Snippets</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"fixed":</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"config":</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"mask":</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"primary":</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"name" : "100"</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"target":</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="mt-base">
                <h3>
                  { &nbsp;&nbsp;"@message-id":&nbsp;"101",<br />
                  &nbsp;&nbsp;"edit-config":&nbsp;{<br />
                </h3>

                <div class="vx-row my-2 flex items-center">
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                    id="input0"
                    style="width: 10vw"
                  />
                  <h3 class="ml-4">:&nbsp;{</h3>
                </div>

                <h3>
                  <span class="mr-12" />"running":&nbsp;null<br />
                  <span class="mr-8" />},<br />
                  <span class="mr-8" />"config":&nbsp;{<br />
                  <span class="mr-12" />"native":&nbsp;{<br />
                  <span class="mr-12" />&nbsp;&nbsp;"interface":&nbsp;{<br />
                  <span class="mr-12" /><span class="mr-8" />"Loopback":&nbsp;{<br />
                </h3>

                <div class="vx-row my-2 flex items-center">
                  <span class="mr-12" /><span class="mr-12" /><br />
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                    id="input1"
                    style="width: 10vw"
                  />
                </div>

                <h3>
                  <span class="mr-12" /><span class="mr-8" />},<br />
                  <span class="mr-12" /><span class="mr-8" />"ip":&nbsp;{<br />
                  <span class="mr-12" /><span class="mr-12" />"address":&nbsp;{<br />
                </h3>

                <div class="vx-row my-2 flex items-center">
                  <span class="mr-12" /><span class="mr-12" />
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                    id="input2"
                    style="width: 10vw"
                  />
                  <h3 class="ml-4">{</h3>
                </div>

                <h3><span class="mr-12" /><span class="mr-12" /><span class="mr-8" />"address":&nbsp;"10.10.10.10",<br /></h3>

                <div class="vx-row my-2 flex items-center">
                  <span class="mr-12" /><span class="mr-12" /><span class="mr-8" />
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                    id="input3"
                    style="width: 10vw"
                  />
                  <h3 class="ml-4">:&nbsp;"255.255.255.255"<br /></h3>
                </div>

                <h3>
                  <span class="mr-12" /><span class="mr-12" />&nbsp;&nbsp;}<br />
                  <span class="mr-12" /><span class="mr-12" />}<br />
                  <span class="mr-12" /><span class="mr-8" />}<br />
                  <span class="mr-12" />&nbsp;&nbsp;}<br />
                  <span class="mr-12" />}<br />
                  <span class="mr-8" />}<br />
                  &nbsp;&nbsp;}<br />
                  }
                </h3>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-command';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === '"config":') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === '"name" : "100"') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === '"primary":') {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input3').textContent === '"mask":') {
        totalScore++;
        document.getElementById('input3').style.borderColor = '#77FAAE';
        document.getElementById('input3').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#Lgx315088047483912192 {
  .answerStandard {
    min-height: 55px;
  }
}

.currently-dragging-standard-command {
  background-color: #0042ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

.command-block {
  display: inline-flex;
  max-height: 100px;
  margin: 5px;
  padding: 10px;
  text-align: left;
  color: #fff;
  border: 1px solid #3f3f3f;
  background-color: #fd4445;
  border-radius: 5px;
}
</style>
